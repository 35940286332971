import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState, AppDispatch } from "../index";
import { useAppSelector, useAppDispatch } from "../hooks/useHooks";
import { Console } from "console";
import { fakestoreapiItemType } from "../fakestoreapi/fakestoreapi.api";

// import { Ferma } from "../../pages/shopingCard/ShopingCard";



const ShopCard_KEY: string = 'ShopCrd';
const bandymas_jsonPlaceholder_KEY: string = 'jsonPlaceholder';


export type basketType = basketItemType[]

export interface basketItemType {
  item: fakestoreapiItemType
  qty: number
}

type checkoutState = "LOADING" | "READY" | "ERROR";



export interface shopingCardType {
  basket: basketType,
  viso: number,
  suma: number,
  checkoutState: checkoutState,
  errorMessage: string,
  // publicapis: publicapis[],
  // jsonPlaceholder: jsonPlaceholder[],
}

const st: shopingCardType = {
  // tvartas:[{vardas:"Gaidys", kiekis:1}],
  basket: [],
  viso: 0,
  suma: 0,
  checkoutState: "READY",
  errorMessage: "Gaidys",
  // publicapis: [],
  // jsonPlaceholder: JSON.parse(localStorage.getItem(bandymas_jsonPlaceholder_KEY) ?? '[]'),
  // 
};
const initialState: shopingCardType = JSON.parse(localStorage.getItem('ShopCrd') ?? JSON.stringify(st));

function aaa(items: any) {
  setTimeout(() => {
    console.log("TimeOut suveikė");
    alert("Gaidys")

  }, items);
  //Payload!!!!!!!!!!!!!!!!!!!!
  return { Message: "Suveikėėėėė!", success: "Ok" }
}
// export const changeStatus:any = createAsyncThunk("manoFerma/checkoutState", async(items:any, {})=>{
//   // const response = await changeStatus(items);  
//   // const {...response} = useGetEntriesQuery(null);
//   //Toliau reikia kažkaip patalpinti data į state************
//   // const { ...response } = await useGetEntriesQuery(null);
//   const response = await aaa(items);

// // console.log(response);

//   return response;
// })

export const changeStatus: any = createAsyncThunk('ferma/changeStatus', async function (_, { rejectWithValue }) {
  try {
    const response = await fetch('https://jsonplaceholder.typicode.com/todos/',
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        // body: JSON.stringify({
        //   completed: !
        // }),
        // mode: 'no-cors',
      }
    );
    if (!response.ok) {
      throw new Error("Serverio klaida!");
    }
    const data = await response.json();
    // console.log(data);
    return { data };
  } catch (error: any) {
    return rejectWithValue(error.message)
  }
})

const shopingCard = createSlice({
  name: "shopingCard",
  initialState,
  reducers: {
    addToCard(state, action) {
      //  let indexLast = state.tvartas.length;
      //  state.tvartas[indexLast] = action.payload;
      let rasta = false;
      state.basket.map((cur) => {
        if (cur.item.id === action.payload.item.id) { cur.qty += action.payload.qty; state.viso += action.payload.qty; state.suma += action.payload.qty * action.payload.item.price; rasta = true; };

      })
      if (!rasta) { state.basket.push(action.payload); state.viso += action.payload.qty; state.suma += action.payload.qty * action.payload.item.price; };

      // let fermaCopy = state.basket.map(a => { return { ...a } });
      // const sutvarkyta: any = sutvarkyti(fermaCopy);
      // // debugger;
      // state.basket = sutvarkyta;

      localStorage.setItem(ShopCard_KEY, JSON.stringify(state))
    },
    removeFromCard(state, action) {
      
      state.viso -= state.basket[action.payload.id].qty; state.suma -= state.basket[action.payload.id].qty * state.basket[action.payload.id].item.price;
      state.basket.splice(action.payload.id, 1);

      
      // state.viso += 1; state.suma += 25;
      localStorage.setItem(ShopCard_KEY, JSON.stringify(state))

    },
    replaceQtyCard(state, action) {
      state.basket.map((cur) => {
        if (cur.item.id == action.payload.id) {
          if (action.payload.action){
            state.viso += 1;
            state.suma += cur.item.price;
            cur.qty+=1;
          
          }else if(cur.qty>=1){
            state.viso -= 1;
            state.suma -= cur.item.price;
            cur.qty-=1;}
          // action.payload.action ? cur.kiekis++ : cur.kiekis--
        }
      })
      localStorage.setItem(ShopCard_KEY, JSON.stringify(state))
    },
    // replacejsonPlaholderChecked(state, action) {
    //   state.jsonPlaceholder.map((cur, index) => {
    //     if (index == action.payload.index) {
    //       cur.completed = !cur.completed;
    //     }
    //   })
    //   localStorage.setItem(bandymas_jsonPlaceholder_KEY, JSON.stringify(state?.jsonPlaceholder))
    // },
    sutvarkytiFerma(state, action) {
      // state.tvartas.map((cur, index)=>{
      //       const index1 =state.tvartas.findIndex((cur1)=>{
      //         return cur1.vardas===cur.vardas;
      //       });
      //       if(index1==index){
      //         console.log("Rado pirmą, kurio index:" + index1)

      //       }else{
      //         console.log("Rado kitą, kurio index" + index);
      //         const oldQty = state.tvartas[index].kiekis;
      //         const addQty = state.tvartas[index1].kiekis;
      //         const newQty = oldQty + addQty;
      //         console.log("Kiekis viso:" + newQty);
      //         state.tvartas[index1].kiekis=newQty;
      //         const arrEl:tvartas|any ={};
      //         state.tvartas.splice(index,1, arrEl);                  
      //       }
      //   });
      //   state.tvartas = state.tvartas.filter((cur)=>cur.kiekis>0);
      state.basket = action.payload;
      localStorage.setItem(ShopCard_KEY, JSON.stringify(state))
    },
    addViso(state, action) {
      state.viso = action.payload
      // localStorage.setItem(LS_FAV_KEY, JSON.stringify(state.viso))
    },
    // addPublicapis(state, action) {
    //   state.publicapis = action.payload
    // }
  },
  extraReducers: function (builder) {
    builder.addCase(changeStatus.rejected, (state, action) => {
      state.checkoutState = "ERROR";
      // state.errorMessage = action.payload.status;
    });
    builder.addCase(changeStatus.fulfilled, (state, action) => {
      state.checkoutState = "READY";
      // console.log(action);
      // state.jsonPlaceholder = action.payload.data;
      // localStorage.setItem(bandymas_jsonPlaceholder_KEY, JSON.stringify(state?.jsonPlaceholder))
    });
    builder.addCase(changeStatus.pending, (state, action) => {
      state.checkoutState = "LOADING";
    });
  }
});

export const { addToCard, removeFromCard, replaceQtyCard, addViso, } = shopingCard.actions;
export default shopingCard.reducer;



// Vietoje to galima naudoti sukurta asinchroninę su createAsyncThunk
// export function changeStatus(){
//   // alert("Kitas gaidys");
//   return (function changeStatusThunk(dispatch:AppDispatch){
//    dispatch({type:"Error"});
//      setTimeout(()=>{
//        dispatch({type:"Ready"});
//      },2000);
//   })
// }


// function sutvarkyti(fermaCopy: fakestoreapiItemType[]) {
//   //  let fermaCopy = ferma.map(a => {return {...a}});
//   let fermaCopy2: any = [];
//   //  console.log(fermaCopy);
//   fermaCopy.map((cur, index) => {
//     const index1 = fermaCopy.findIndex((cur1) => {
//       return cur1.vardas === cur.vardas;
//     });
//     if (index1 == index) {
//       console.log("Rado pirmą, kurio index:" + index1)
//     } else {
//       console.log("Rado kitą, kurio index" + index);
//       const oldQty = fermaCopy[index].kiekis;
//       const addQty = fermaCopy[index1].kiekis;
//       const newQty = oldQty + addQty;
//       console.log("Kiekis viso:" + newQty);
//       fermaCopy[index1].kiekis = newQty;
//       const arrEl: tvartas | any = {};
//       fermaCopy.splice(index, 1, arrEl);
//     }
//     fermaCopy2 = fermaCopy.filter((cur) => cur.kiekis > 0);
//   });
//   return fermaCopy2;
// }
