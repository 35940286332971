import { configureStore } from "@reduxjs/toolkit";
import { fakestoreapi } from "./fakestoreapi/fakestoreapi.api";
import todoState from "./todoState/TodosSlice";
import shopingCard from "./shopingCard/shopingCardSlice";
import { users } from "./users/users.api";
import { setupListeners } from "@reduxjs/toolkit/query";

export const store = configureStore({
  reducer: {
    todo: todoState,
    shopingCard: shopingCard,
    [fakestoreapi.reducerPath]: fakestoreapi.reducer,
    [users.reducerPath]: users.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(users.middleware, fakestoreapi.middleware),
  // getDefaultMiddleware().concat(publicapis.middleware, users.middleware),
});
setupListeners(store.dispatch);
// window.store=store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
