import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { RootState } from "../index";
import { useAppDispatch, useAppSelector } from "../hooks/useHooks";

const Todo_KEY: string = "TodoState";

export interface todos {
  todo: todo[];
  todoStatus: string;
  statusMessage: any;
}

export interface todo {
  id: number;
  uzduotis: string;
  ivykdyta: boolean;
}
export function getErrorMessage(error: any) {
  return (
    error?.response?.data?.message ||
    error?.response?.data.error ||
    error?.response?.data ||
    error?.message ||
    error.toString()
  );
}

// export const fetchTodos = createAsyncThunk<any, void, {rejectValue:string}>('todo/fetchTodos', async function (_, { rejectWithValue }) {
export const fetchTodos: any = createAsyncThunk(
  "todo/fetchTodos",
  async function (_, { rejectWithValue, fulfillWithValue }) {
    try {
      // localhost pakeista į todo.macrol.lt
      // const response: any = await fetch("http://todo.macrol.lt/users/todos", {

      // debugger;
      const response: any = await fetch("https://todo.macrol.lt/users/todos", {
        // const response: any = await fetch("http://localhost:3005/users/todos/", {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
        // body: JSON.stringify({
        //   completed: "",
        // }),
        // mode: "no-cors",
      });
      // console.log(response, "Response");
      // return response;
      // alert("Response ok!");
      // alert("Laukia duomenų");
      const data = await response.json();
      // alert("Duomenys gauti");
      // // console.log(data);
      // let data1 = [
      //   {
      //     id: 119,
      //     ivykdyta: 0,
      //     uzduotis: "Apvadų pjovimo įrankis gaidys",
      //   },
      //   {
      //     id: 1111,
      //     ivykdyta: 1,
      //     uzduotis: "gaidys",
      //   },
      // ];

      return fulfillWithValue(data);
    } catch (error: any) {
      // if (!error.response) {
      //   throw error;
      // }
      // alert(error);
      // return error;
      // alert("Klaida");
      return rejectWithValue(error);
    }
  }
);
export const deleteTodos: any = createAsyncThunk(
  "todo/deleteTodos",
  async function (
    { id, index }: { id: number; index: number },
    { rejectWithValue, dispatch }
  ) {
    try {
      const response = await fetch(`https://todo.macrol.lt/users/todos:${id}`, {
        // const response = await fetch(`http://localhost:3005/users/todos:${id}`, {
        method: "DELETE",
        //   headers: {
        //     'Content-Type': 'application/json',
        //   },
        // body: JSON.stringify({
        //   completed: !
        // }),
        // mode: "no-cors",
      });
      if (!response.ok) {
        throw new Error("Serverio klaida!");
      }
      const data = await response.json();
      // console.log("trynimas veikia");
      // console.log(data);
      dispatch(deleteTodo({ index: index }));
      return { data };
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);
export const addTodos: any = createAsyncThunk(
  "todo/addTodos",
  async function (
    { ...newTodos }: { uzduotis: string; ivykdyta: boolean },
    { rejectWithValue, dispatch }
  ) {
    try {
      const response = await fetch(`https://todo.macrol.lt/users`, {
        // const response = await fetch(`http://localhost:3005/users`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ newTodos }),
        // mode: "no-cors",
      });
      if (!response.ok) {
        throw new Error("Serverio klaida!");
      }
      const data = await response.json();
      // console.log(data);
      dispatch(addTodo({ ...newTodos, id: data.insertId })); // problema su id, kuris gaunamas DB automatiškia. Todėl jį iš ten reikia gauti ir persiųsti į vietinį state
      // dispatch(fetchTodos());
      return { data };
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);
export const toggleTodos: any = createAsyncThunk(
  "todo/toggleTodos",
  async function (
    { ...toggleTodos }: { id: number; ivykdyta: boolean },
    { rejectWithValue, dispatch, getState }
  ) {
    // const state:any = getState();
    // console.log(state.todo.todo);
    try {
      const response = await fetch(`https://todo.macrol.lt/users`, {
        // const response = await fetch(`http://localhost:3005/users`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...toggleTodos,
          ivykdyta: !toggleTodos.ivykdyta,
        }),
        // mode: "no-cors",
      });
      if (!response.ok) {
        throw new Error("Serverio klaida!");
      }
      const data = await response.json();
      dispatch(
        changeIvykdyta({ ...toggleTodos, ivykdyta: !toggleTodos.ivykdyta })
      );
      // console.log(data);
      // dispatch(addTodo({...newTodos, id:data.insertId})); // problema su id, kuris gaunamas DB automatiškia. Todėl jį iš ten reikia gauti ir persiųsti į vietinį state
      // dispatch(fetchTodos());
      return { data };
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

const st = {
  todo: [
    {
      uzduotis: "Išmokti React",
      ivykdyta: true,
    },
    {
      uzduotis: "Išmokti JSSSSSSSSSSS",
      ivykdyta: false,
    },
  ],
  todoStatus: "Ready",
  statusMessage: "Nieko",
};
const initialState: todos = JSON.parse(
  localStorage.getItem("TodoState") ?? JSON.stringify(st)
);

const todoState = createSlice({
  name: "todo",
  initialState,
  reducers: {
    changeIvykdyta(state, action) {
      // state.todo[action.payload.id].ivykdyta=!state.todo[action.payload.id].ivykdyta;
      state.todo.map((cur) => {
        if (cur.id === action.payload.id) {
          cur.ivykdyta = action.payload.ivykdyta;
        }
      });
      // console.log(state);
      localStorage.setItem(Todo_KEY, JSON.stringify(state));
    },
    addTodo(state, action) {
      state.todo.push(action.payload);
      localStorage.setItem(Todo_KEY, JSON.stringify(state));
    },
    deleteTodo(state, action) {
      state.todo = state.todo.filter(
        (item, index) => index !== action.payload.index
      );
      localStorage.setItem(Todo_KEY, JSON.stringify(state));
    },
  },
  extraReducers: function (builder) {
    //tai sukelia papildomus renderingus!!!!!!!!!!!!!!!!!!!!
    builder.addCase(fetchTodos.rejected, (state, action) => {
      state.todoStatus = "ERROR";
      state.statusMessage = action.error.message;
      // state.statusMessage = "Bybis kamine";
    });
    builder.addCase(deleteTodos.rejected, (state, action) => {
      state.todoStatus = "ERROR";
      state.statusMessage = action;
    });
    builder.addCase(fetchTodos.fulfilled, (state, action) => {
      state.todoStatus = "READY";
      console.log("action");
      state.todo = action.payload;
      state.statusMessage = "Duomenys sėkmingai atnaujinti"; //reikia, kad po laiko išnyktų
      // localStorage.setItem(bandymas_jsonPlaceholder_KEY, JSON.stringify(state?.jsonPlaceholder))
    });
    builder.addCase(deleteTodos.fulfilled, (state, action) => {
      state.todoStatus = "READY";
      // state.statusMessage = action.payload;
      // console.log(action);
      state.statusMessage =
        "Ištrinta įrašų: " + action.payload.data.affectedRows;
    });
    builder.addCase(toggleTodos.fulfilled, (state, action) => {
      state.todoStatus = "READY";
      // state.statusMessage = action.payload;
      // console.log(action);
      state.statusMessage = "Statusas sėkmingai pakeistas";
    });
    builder.addCase(addTodos.fulfilled, (state, action) => {
      state.todoStatus = "READY";
      // state.statusMessage = action.payload;
      // console.log(action);
      state.statusMessage =
        "Pridėta įrašų: " + action.payload.data.affectedRows;
    });
    builder.addCase(fetchTodos.pending, (state, action) => {
      state.todoStatus = "LOADING";
    });
  },
});

const { changeIvykdyta, addTodo, deleteTodo } = todoState.actions;
export default todoState.reducer;
