import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export interface UsersUI {
  name: string;
  pavarde: string;
  email: string;
  id: number;
  password: string;
  create_at: string;
}
export interface ErrorUI {
  Error: string;
}

export const users: any = createApi({
  reducerPath: "users",
  tagTypes: ["Users"],
  baseQuery: fetchBaseQuery({
    baseUrl: "https://todo.macrol.lt/",
    // baseUrl: "http://localhost:3005/",
    mode: "cors",
    // mode: "no-cors",
    // credentials: "include",
    // credentials: "same-origin",

    //***********sukuriame custom headers */
    // prepareHeaders: (headers) => {
    //     const accessToken =  getCookie("token");
    //     console.log(accessToken);
    //     if (accessToken) {
    //         headers.set("Cookie", `token= ${accessToken}`);
    //         headers.set("Content-Type", "application/json");
    //         headers.set("Access-Control-Allow-Credentials", "true");
    //         headers.set("Access-Control-Allow-Headers", "Coookie");

    //     }

    //     return headers;
    // },
    //**************************************************** */
  }),

  endpoints: (build) => ({
    getUsers: build.query<UsersUI[] | string | ErrorUI | any, null>({
      query: () => ({
        // port: ':3005/',
        url: "users",

        // url: 'login/users?title=Rolas',
        // params:'users?title=Rolas',
        // params: {
        //     per_page:15
        // }
        // method:'GET',
        // headers: {
        //     'Content-Type': 'application/json', // type
        //     'Access-Control-Allow-Credentials': 'true',
        //     'Access-Control-Allow-Headers': 'Coookie',
        // },
        // mode: "cors",
        // credentials: 'include',
      }),
      providesTags: (result: UsersUI[]) =>
        result
          ? [
              { type: "Users", id: "LIST" },
              ...result.map(({ id }) => ({ type: "Users" as const, id })),
            ]
          : [{ type: "Users", id: "LIST" }],
      // query: ({ id, ...patch }) => ({
      //     url: `posts/${id}`,
      //     method: 'PUT',
      //     body: patch,
      //   }),
      transformResponse(response: any) {
        return response;
      },
      // transformResponse: response =>({
      //   gaidys:response,
      // }),
      // transformResponse: (response: { data: any }, meta, arg) => response.data,
      // Pick out errors and prevent nested properties in a hook or selector
      transformErrorResponse: (
        response: { status: string | number },
        meta,
        arg
      ) => response.status,
    }),
    loginUsers: build.mutation<any, string>({
      query: (userName) => ({
        // port: ':3005/',
        url: `login/users?title=${userName}`,
        method: "GET",
      }),
      invalidatesTags: [{ type: "Users", id: "LIST" }],
    }),
    delUsers: build.mutation<any, number>({
      query: (id) => ({
        // port: ':3005/',
        url: `users:${id}`,
        // url: 'login/users?title=Rolas',
        // params:'users?title=Rolas',
        // params: {
        //     per_page:15
        // }
        method: "DELETE",
        // headers: {
        //     'Content-Type': 'application/json', // type
        //     'Access-Control-Allow-Credentials': 'true',
        //     'Access-Control-Allow-Headers': 'Coookie',
        // },
        // mode: "cors",
        // credentials: 'include',
      }),
      // query: ({ id, ...patch }) => ({
      //     url: `posts/${id}`,
      //     method: 'PUT',
      //     body: patch,
      //   }),
      // transformResponse(response:any) {
      //     console.log(response);
      //     return response;
      // },
      invalidatesTags: [{ type: "Users", id: "LIST" }],
    }),
    addUsers: build.mutation<any, number>({
      query: (body) => ({
        // port: ':3005/',
        url: `users`,
        body: body,
        // url: 'login/users?title=Rolas',
        // params:'users?title=Rolas',
        // params: {
        //     per_page:15
        // }
        method: "POST",
        // headers: {
        //     'Content-Type': 'application/json', // type
        //     'Access-Control-Allow-Credentials': 'true',
        //     'Access-Control-Allow-Headers': 'Coookie',
        // },
        // mode: "cors",
        // credentials: 'include',
      }),
      // query: ({ id, ...patch }) => ({
      //     url: `posts/${id}`,
      //     method: 'PUT',
      //     body: patch,
      //   }),
      // transformResponse(response:any) {
      //     console.log(response);
      //     return response;
      // },
      invalidatesTags: [{ type: "Users", id: "LIST" }],
    }),
  }),
});

export const {
  useGetUsersQuery,
  useDelUsersMutation,
  useAddUsersMutation,
  useLoginUsersMutation,
} = users;

function getCookie(cname: any) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}
