import { NavLink } from 'react-router-dom';
import '../styles/users.css';

export function Menu() {
   
    return (
        <>
            <div className="menu">
                <NavLink className={({ isActive, isPending }) => isPending ? "pending " : isActive ? "active " : "menuItem"} to='/'>Home</NavLink>
                <NavLink className={({ isActive, isPending }) => isPending ? "pending " : isActive ? "active " : "menuItem"} to='/users'>Priminimai</NavLink>
                <NavLink className={({ isActive, isPending }) => isPending ? "pending " : isActive ? "active " : "menuItem"} to='/item'>One item card</NavLink>
                <NavLink className={({ isActive, isPending }) => isPending ? "pending " : isActive ? "active " : "menuItem"} to='/shopingcard'>Shoping card</NavLink>
            </div>
            {/* Menu padaryti su ClassNames!!!!!!!!! */}
        </>
    )
}