import React, { useEffect } from "react";
import '../styles/users.css';
import { useAppDispatch, useAppSelector } from "../store/hooks/useHooks";
import { addViso, basketItemType } from "../store/shopingCard/shopingCardSlice";
import { useLocation } from "react-router-dom";

export function ShopCardIcon() {
    // const ShopCardViso: any = useAppSelector((state) => state.shopingCard.viso);
    // const ferma = useAppSelector((state) => state.shopingCard.basket);
    // const dispatch = useAppDispatch();



    const totalItems = useAppSelector((state)=>{
        // console.log(state.shopingCard.basket);
        return state.shopingCard.basket.reduce((total:number, cur:basketItemType)=>{
            total += cur.qty;
            return total;
        },0)    
    });
    // console.log(totalItems);


    const totalPay = (useAppSelector((state)=>{
        // console.log(state.shopingCard.basket);
        return state.shopingCard.basket.reduce((total:number, cur:basketItemType)=>{
            total += cur.item.price*cur.qty;
            return total;
        },0)    
    })).toFixed(2);
    // console.log(totalPay);

    // const location = useLocation();

    // const viso = useAppSelector((state)=>state.ferma.viso);
    // useEffect(() => {
    //     const viso = ferma.reduce((total, curr) => {
    //         // return total + curr.kiekis;
    //     }, 0);
    //     console.log("Pasikeite pirkimų krepšas. Viso: " + viso);
    //     dispatch(addViso(viso));
    // }, [ferma])

    return (
        <>
            <div className="shopingIconElmn">
                <button onClick={()=>{window.location.hash="/shopingcard"}} className="schopBtn">{totalItems} <i className="schopBtn icon">{totalPay+" Eur"}</i></button>
            </div>
        </>
    )
}