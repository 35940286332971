import React, { ContextType, Suspense, createContext, lazy, useState } from "react";
import { Route, Routes } from "react-router-dom";
//import { Home } from "./pages/home/Home";
import { Virsus } from "./Components/Header";
//import { Users } from "./pages/users/Users";
import './styles/users.css';
//import { Item } from "./pages/Item/Item";
//import { ShopingCard } from "./pages/shopingCard/ShopingCard";
//import PropTypes from 'prop-types'

const Home = lazy(() => import('./pages/home/Home'));
const Users = lazy(() => import('./pages/users/Users'));
const Item = lazy(() => import('./pages/Item/Item'));
const ShopingCard = lazy(() => import('./pages/shopingCard/ShopingCard'));


export default function Loading() {
    return <p><i>Kraunasi...</i></p>;
}

const vardas = {vardas:"Rolandas", age:48};
const gaidys = "Gaidžio kontekstas";

export type vardasType = {vardas:string, age:number}

export const VardoContext:React.Context<vardasType> = createContext<vardasType>(vardas);

export function AppRouter(): any {
    return (
        <>
            <Virsus />
            <div className="nuoVirsaus">
                <Suspense fallback={<Loading />}>
                    <Routes >
                        <Route path="/" element={<Home />} />
                        <Route path="/users" element={<Users />} />
                        <Route path="/item" element={
                        <VardoContext.Provider value={vardas}>
                            <Item />
                        </VardoContext.Provider>
                        } />
                        <Route path="/shopingcard" element={<ShopingCard />} />
                    </Routes>
                </Suspense>
            </div>
        </>
    )

}