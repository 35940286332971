import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export type fakestoreapiType = fakestoreapiItemType[]

export interface fakestoreapiItemType {
    id: number
    title: string
    price: number
    description: string
    category: string
    image: string
    rating: RatingType
}

export interface RatingType {
    rate: number
    count: number
}


export const fakestoreapi = createApi({
    reducerPath: 'fakestoreapi',
    baseQuery: fetchBaseQuery({
        baseUrl: 'https://fakestoreapi.com'
    }),
    endpoints: build => ({
        getItems: build.query<fakestoreapiType[], null>({
            query: () => ({
                url: '/products',
                // params: {
                //     per_page:15
                // }
            }),
            // transformResponse(response:any) {
            //     return response
            // },
        })
    })

})


export const { useGetItemsQuery } = fakestoreapi;