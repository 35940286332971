import React, { useEffect } from "react";
import { NavLink } from 'react-router-dom';
import '../styles/users.css';
import { useAppDispatch, useAppSelector } from "../store/hooks/useHooks";
import { addViso } from "../store/shopingCard/shopingCardSlice";
import { Menu } from "./Menu";
import { ShopCardIcon } from "./ShopCardIcon";




export function Virsus() {
    // const ShopCardViso: any = useAppSelector((state) => state.shopingCard.viso);

    // const ferma = useAppSelector((state) => state.shopingCard.basket);
    // const dispatch = useAppDispatch();
    // const viso = useAppSelector((state)=>state.ferma.viso);
    // useEffect(() => {
    //     const viso = ferma.reduce((total, curr) => {
    //         return total + curr.kiekis;
    //     }, 0);
    //     console.log("Pasikeite pirkimų krepšas. Viso: " + viso);
    //     dispatch(addViso(viso));
    // }, [ferma])

    return (
        <>
            <div className="header">
                <Menu />
                {/* <div className="shopingIconElmn">
                    <button className="schopBtn">{ShopCardViso} <i className="schopBtn icon"></i></button>
                </div> */}
                <ShopCardIcon/>

            </div>

        </>
    )
}